<div class="carousel">
  <div *ngFor="let slide of slides; let i = index">
    <img
      *ngIf="i === currentSlide"
      [src]="slide.src"
      class="slide"
      [@slideAnimation]="animationType"
    />
  </div>

  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
