import {Component, OnInit} from '@angular/core';
import {Contact} from "../_models/contactModel";
import '../../assets/js/smtp.js';
import {FormControl, FormGroup} from "@angular/forms"; //file path may change →
declare var Email;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  model: Contact = new Contact();
  lat = 41.002060;
  lng = 28.819643;
  submitted = false;
  f: FormGroup;

  constructor() {
    this.f = new FormGroup({
      'name': new FormControl(),
      'email': new FormControl(),
      'phone': new FormControl(),
      'message': new FormControl()
    })
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    /* Email.send({
       Host: `smtp.elasticemail.com`,
       Username: `gizemzorba@aifasoft.com`,
       Password: `442A036F0AF040AF40E0CD638033CC3E5336`,
       To: `gizemzorba@aifasoft.com`,
       From: this.model.email,
       Subject: `KONU`,
       Body: ` ${this.model.message} `
     }).then(message => {
       alert(message);
   });

       *   Email.send({
         Host: `smtp.elasticemail.com`,
         Username: `gizemnzorba@gmail.com`,
         Password: `77CED19919A649C296F2828BC7022E5D07B9`,
         To: `gizemnzorba@gmail.com`,
         From: this.model.email,
         Subject: `KONU`,
         Body: ` ${this.model.message} `
       }).then(message => {
         alert(message);
         this.f.      });
   */

    Email.send({
      //SecureToken: "10ED90705DE8238A6DD8D007810DC1BA9E04",
      SecureToken: "cd85fee3-d2aa-43cf-8755-69c91aff3dd8",
      To: 'info@aifasoft.com',
      From: 'gizemzorba@aifasoft.com',
      //From: 'cihan.turgut@aifasoft.com' ,
      Subject: "Subject",
      Body: ` <i> ${this.model.email} Kulanıcısından bir mail </i> <br/> <b>İsim: </b>${this.model.name} <br />  <b>Mesaj:</b> <br /> ${this.model.message} <br><br>  `
    }).then(
      //message => alert(message)
      this.f.setValue({name: '', email: '', phone: '', message: ''})
    );


  }

}
