import {Component, OnInit} from '@angular/core';
import {webSocket} from 'rxjs/webSocket';
import {servers} from '../../shared/configuration';
import {SocketData} from '../../_models/socketData';
import {CATEGORYTYPE} from '../../_models/categoryType';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  connection = webSocket(servers.real);
  footerList: SocketData[] = [];
  footerListReplace: SocketData[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.connection.subscribe((data: SocketData[]) => {
      if (this.footerList) {
        this.footerList = [];
      }

      data.forEach(item => {
        if (item.Category === CATEGORYTYPE.MADEN || item.Category === CATEGORYTYPE.ZIYNET || item.Category === CATEGORYTYPE.GRAMALTIN) {
          this.footerList.push(item);
        }
      });
      if (this.footerListReplace.length !== 0) {
        if (JSON.stringify(this.footerListReplace) === JSON.stringify(this.footerList)) {

        } else {
          this.footerList.forEach((data, index) => {
            if (data.Ask !== this.footerListReplace[index].Ask) {
              this.percentChange(data, this.footerListReplace[index], index);
            } else {
              data.askPercentChange = 0.00;
              this.footerListReplace[index].askPercentChange = data.askPercentChange;
            }
          });
        }
      } else {
        this.footerListReplace = this.footerList;
      }
    });
  }

  trackByPrice(index: number, code): void {
    return code.Ask;
  }

  percentChange(newData, oldData, index): void {
    if (newData.Ask !== oldData.Ask) {
      const oldAskPrice = +oldData.Ask;
      const newAskPrice = +newData.Ask;
      const askPriceDifference = (1 - (oldAskPrice / newAskPrice)) * 100;
      newData.askPercentChange = +askPriceDifference.toFixed(2);
      newData.Time = Date.now();
      if (askPriceDifference < 0) {
        const code = newData.Code;
        const element = document.getElementById(code);
      } else if (askPriceDifference > 0) {
        const code = newData.Code;
        const element = document.getElementById(code);
      }
    }
  }

}
