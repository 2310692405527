<div class="contact-container">
    <div class="maps-form col-sm-12 col-md-12 col-lg-12">
        <div class="tel-social">
            <div class="tel col-sm-12 col-md-12 col-lg-12">
                <h5 class="title">Telefon</h5>
                <p class="subtitle"> 0212 677 66 66</p>
                <h5 class="title">E-posta</h5>
                <p class="subtitle">info@tatarogludoviz.com</p>
            </div>
        </div>
        <div class="address">
            <h5 class="title">Adres</h5>
            <p class="map-text">
                Mimar Kemalettin Mah. Ordu Cad. Özbek Çarşısı Blok No:13 A Beyazıt-Fatih/İstanbul</p>
        </div>
    </div>
    <div class="contact-form col-sm-12 col-md-12 col-lg-12">
        <h1 class="title">BİZE ULAŞIN</h1>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.6833679039819!2d28.962550529253804!3d41.00920651070952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab98fc73ec0bd%3A0x351175379fcf1f61!2zw5Z6YmVrIMOHYXLFn8Sxc8Sx!5e0!3m2!1str!2str!4v1619693484333!5m2!1str!2str"
            width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
</div>