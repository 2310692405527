﻿export enum CATEGORYTYPE {
  DOVIZ = 'DOVIZ',
  ZIYNET = 'ZIYNET',
  PARITE = 'PARITE',
  MADEN = 'MADEN',
  GRAMALTIN = 'GRAM ALTIN',
  PERAKENDE = 'PERAKENDE',
}

/**
 * Kategori ismini geri döndüren fonksiyon
 export function categoryTypeName(Category: CATEGORYTYPE): string {
  switch (Category) {
    case CATEGORYTYPE.DOVIZ:
      return 'Döviz';
      break;
    case CATEGORYTYPE.ZIYNET:
      return 'Ziynet';
      break;
    case CATEGORYTYPE.PARITE:
      return 'Parite';
      break;
    case CATEGORYTYPE.MADEN:
      return 'Maden';
      break;
    case CATEGORYTYPE.GRAMALTIN:
      return 'Gram Altın';
      break;
  }
}
 */
