<div appNoRightClick class="page-wrapper">
  <app-navbar></app-navbar>
  <app-chatbox></app-chatbox>
  <router-outlet></router-outlet>
</div>

<!--
<app-navbar></app-navbar>
<div class=" page-wrapper" [ngClass]="{'toggled' : getSideBarState()}">
  <app-sidebar></app-sidebar>
  <div class="main-page" [style.margin-left]="getSideBarState()===false? '250px': '15px'">
    <router-outlet></router-outlet>

  </div>
  <app-footer></app-footer>
  <app-chatbox></app-chatbox>
</div>
-->
