import {AfterViewInit, Component, OnInit} from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    AOS.init();
  }
}
