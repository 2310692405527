<!--<div class="chart-wrapper">
  <canvas baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins">
  </canvas>
</div> -->
<!--
<div style="width:98%; height:98%;">
  <object
    type="text/html"
    data="http://tvc4.forexpros.com/init.php?family_prefix=tvc4&carrier=037ffb31b7160060201cb761bc41e549&time=1597872594&domain_ID=10&lang_ID=10&timezone_ID=63&pair_ID=18&interval=900&refresh=4&session=24x7&client=1&user=214099738&width=650&height=750&init_page=instrument&m_pids=&watchlist=&site=http://www.aifafx.com"
    style="width:100%; height:100%; margin:1%;"
  ></object>
</div>
-->
<div id="chart" class="chart-cont">


</div>




