import {AfterViewInit, Component, OnInit} from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-kurumsal',
  templateUrl: './kurumsal.component.html',
  styleUrls: ['./kurumsal.component.scss']
})
export class KurumsalComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    AOS.init();
  }
}
