import {
  AfterViewInit, ChangeDetectionStrategy,
  Component, DoCheck,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2, SimpleChange,
  SimpleChanges
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ThemeService} from '../../_services/theme.service';

declare const TradingView: any;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {
  themeName: string = 'dark';
  @Input() code: string;


  constructor(private renderer: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private themeService: ThemeService) {
  }


  ngOnChanges(changes: SimpleChanges) {
    // const currentItem: SimpleChange = changes.code;
    if (this.code === '' || this.code === null) {
    } else {
      this.ngAfterViewInit();
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    new TradingView.widget(
      {
        width: '100%',
        height: 350,
        symbol: this.code,
        interval: '1',
        timezone: 'Europe/Istanbul',
        theme: this.themeName,
        style: '2',
        locale: 'tr',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: true,
        save_image: false,
        container_id: 'chart'
      }
    );
  }

  ngDoCheck(): void {
    if (this.themeName !== this.themeService.getActiveTheme().name) {
      this.themeName = this.themeService.getActiveTheme().name;
      this.ngAfterViewInit();
    }
  }
}


