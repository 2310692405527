<div class="request-form">
    <div class="container-fluid">
        <div class="row">
            <div class="left-div col-sm-12 col-md-12 col-lg-12">
                <div class="banner-img"></div>
            </div>
        </div>
        <div class="container">
            <div class="right-div first col-sm-12 col-md-12 col-lg-6">
                <div class="overflow-box">
                    <div class="grid-box" *ngFor="let data of dataList1 ; let i=index; trackBy : trackByPrice">
                        <div class="grid-card" [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
                            <span class="grid-text col-sm-3 col-md-3 col-lg-2">
         {{data.Code}}
       </span>
                            <div class="price-tag col-sm-9 col-md-9 col-lg-9">
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange>0" style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-arrow-up"></i>
              </span>
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange<0" style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-arrow-down"></i>
              </span>
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange===0 || !data.askPercentChange" style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{data.Bid.toFixed(3) | number:'1.2-4'}}
       </span>
                                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{data.Ask.toFixed(3) | number:'1.2-4'}}
       </span>
                                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-div second col-sm-12 col-md-12 col-lg-6">
                <div class="overflow-box">
                    <div class="grid-box" *ngFor="let data of dataList2 ; let i=index; trackBy : trackByPrice">
                        <div class="grid-card" [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
                            <span class="grid-text col-sm-3 col-md-3 col-lg-2">
         {{data.Code}}
       </span>
                            <div class="price-tag col-sm-9 col-md-9 col-lg-9">
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange>0" style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-arrow-up"></i>
              </span>
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange<0" style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-arrow-down"></i>
              </span>
                                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="data.askPercentChange===0 || !data.askPercentChange" style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{data.Bid.toFixed(3) | number:'1.2-4'}}
       </span>
                                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{data.Ask.toFixed(3) | number:'1.2-4'}}
       </span>
                                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="phone-content">
    <div class="timeline">
        <div class="container1 left">
            <div data-aos="fade-up" class="content">
                <div class="applogo-button"></div>
            </div>
        </div>
        <div class="container1 telephone">
            <div data-aos="fade-up" class="content">
                <div class="mobile-image"></div>
            </div>
        </div>
        <div class="container1 left">
            <div data-aos="fade-up" class="content">
                <h2>Mobil Uygulama</h2>
                <p>Her zaman, her yerde fiyatlarımızı takip edebilirsiniz.</p>
            </div>
        </div>
        <div class="container1 right">
            <div data-aos="fade-up" class="content">
                <h2>Erişilebilirlik</h2>
                <p>Her zaman, her yerde fiyatlarımızı takip edebilirsiniz.</p>
            </div>
        </div>
        <div class="container1 left">
            <div data-aos="fade-up" class="content">
                <h2>Güvenli</h2>
                <p>Fiyatlarımıza en güvenli şekilde ulaşın.</p>
            </div>
        </div>
        <div class="container1 right">
            <div data-aos="fade-up" class="content">
                <h2>Kârlılık</h2>
                <p>Güncel fiyatlar ile kazancınızı en üst düzeye taşıyın.</p>
            </div>
        </div>
        <div class="container1 left">
            <div data-aos="fade-up" class="content">
                <h2>Zaman</h2>
                <p>Telefon görüşmeleri ile zaman kaybetmeyin ve fiyat kaçırmayın.</p>
            </div>
        </div>
        <div class="container1 right">
            <div data-aos="fade-up" class="content">
                <h2>Çevirici</h2>
                <p>Çevirici ile kurlar arası fiyat değişminizi görüntüleyebilirsiniz.</p>
            </div>
        </div>
    </div>
</div>

<div class="content-footer">
    <div class="who">
        <div style="height: 100% " class="wyre-container col-lg-4 col-md-2  d-none d-lg-block">
            <div class="footer-logo"></div>
        </div>
        <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
            <div class="who-content">
                <h1>Adres</h1>
                Mimar Kemalettin Mah. Ordu Cad. Özbek Çarşısı Blok No:13 A Beyazıt-Fatih/İstanbul
            </div>
        </div>
        <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
            <div class="who-content">
                <h1>Telefon</h1>
                <a style="color:#000;" href="tel:02126776666">0212 677 66 66</a><br>
            </div>
        </div>
        <div class="wyre-container col-lg-12 col-md-12 col-sm-12">
            Powered by <a style="margin-left: 5px" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
        </div>
    </div>
</div>