<div class="slider">
  <div class="slide-track">
    <div class="box2" *ngFor="let data of footerList; trackBy : trackByPrice">
      <p class="head">{{data.Code}}</p>
      <p class="title"
         [ngClass]="{'title-up': data.askPercentChange >0,
                 'title-down': data.askPercentChange <0}">{{data.Ask.toFixed(3)| number:'1.4-4'}}
        <i *ngIf="data.askPercentChange>0" class="fa fa-angle-up"></i>
        <i *ngIf="data.askPercentChange<0" class="fa fa-angle-down"></i>
        <i *ngIf="data.askPercentChange===0 || !data.askPercentChange" class="fa fa-minus"></i>
      </p>
    </div>
  </div>
</div>
