<nav class="navbar navbar-expand-lg col-12">
  <a class="navbar-brand col-sm-5 col-md-2 col-lg-3" (click)="route('home')">
    <div class="logo-img">
    </div>
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse col-sm-12 col-md-12 col-lg-9 show" id="navbarSupportedContent"
       [ngClass]="{ 'show': navbarOpen }">
    <div class="navbar-nav col-12 col-sm-12 col-md-12 col-lg-12">
      <div id="home" class="nav-item">
        <div class="text" (click)="route('home')">
          <a class="nav-text"> ANASAYFA </a>
        </div>
      </div>
      <div id="currency" class="nav-item ">
        <div class="text" (click)="route('currency')">
          <a class="nav-text"> CANLI PİYASA </a>
        </div>
      </div>
      <div id="info" class="nav-item">
        <div class="text" (click)="route('info')">
          <a class="nav-text"> HAKKIMIZDA </a>
        </div>
      </div>
      <div id="kurumsal" class="nav-item">
        <div class="text" (click)="route('kurumsal')">
          <a class="nav-text"> KURUMSAL </a>
        </div>
      </div>
      <div id="comm" class="nav-item">
        <div class="text" (click)="route('comm')">
          <a class="nav-text"> İLETİŞİM </a>
        </div>
      </div>
      <div class="nav-item" (click)="toggleTheme()">
        <div class="wrapper">
          <div class="toggle">
            <input class="toggle-input" type="checkbox" checked="false" />
            <div class="toggle-bg"></div>
            <div class="toggle-switch">
              <div class="toggle-switch-figure"></div>
              <div class="toggle-switch-figureAlt"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<app-footer></app-footer>
