export interface Theme {
  name: string;
  properties: any;
}


export const light: Theme = {
  name: 'dark',
  properties: {
    '--logo-color': 'rgb(89, 74, 45)',
    '--logo-color1': '#003471',

    '--navbar-color': '#272727',
    '--navbar-hover-color': '#897343',
    '--navbar-bg': '#292929',
    '--red-navbar-color': '#891910',
    '--navbar-text-color': '#ffffff',

    '--background-color': '#ffffff',
    '--footer-color': '#891910',
    '--footer-text': 'rgb(172, 145, 85)',
    '--footer-text-down': '#f25961',
    '--footer-text-up': '#3a9d37',
    '--title-area': '#41655b',
    '--title-color': '#121212',
    '--title-color2': '#131722',
    '--title-black-color': '#ffffff',
    '--title-black-color2': '#ffffff',
    '--scrollbar-color': '#41655b',
    '--scrollbar-hover': '#41655b',

    '--fas-icon': '#e7e7e7',
    '--fas-icon-hover': '#ffffff',
    '--prev-next-hover': 'rgba(255, 255, 255, 0.8)',

    '--btn-name-color': '#b98d26',
    '--btn-name-hover': '#801a11',
    '--btn-name-text': '#ffffff',

    '--grid-text': '#cd5c5c',
    '--grid-text2': '#cd5c5c',

    '--bg-white-color': '#292929',
    '--bg-white-color2': '#181818',
    '--bg-black-color': '#fefefe',
    '--background-tertiary-shadow': '0 20px 30px rgba(255, 255, 255, 0.1)',
    '--box-tertiary-shadow': '0 0 50px rgba(255, 255, 255, 0.5)',

    '--logo-image': 'url(assets/img/footer-logo1.png)',
    '--chart-theme': 'dark',
    '--banner-image': 'url(assets/img/banner1white.jpg)',
    '--banner-image2': 'url(assets/img/banner2dark.jpg)',

    '--phone-image': 'url(assets/img/phone2.png)',
    '--applogo-image': 'url(assets/img/applogo1.png)',

    '--content-icon1': 'url(assets/img/1-dark.png)',
    '--content-icon2': 'url(assets/img/2-dark.png)',
    '--content-icon3': 'url(assets/img/3-dark.png)',
    '--content-icon4': 'url(assets/img/6-dark.png)',
    '--content-bg': '#ededed',
  }
};

export const dark: Theme = {
  name: 'light',
  properties: {
    '--logo-color': '#c69a4c',
    '--logo-color1': '#003471',

    '--navbar-color': '#e7d7b5',
    '--navbar-hover-color': '#897343',
    '--navbar-bg': '#ffffff',
    '--red-navbar-color': '#000000',
    '--navbar-text-color': '#000000',

    '--background-color': 'rgba(18, 26, 33, 0.8)',
    '--footer-color': '#ad0000',
    '--footer-text': 'rgb(83,69,39)',
    '--footer-text-down': '#931d26',
    '--footer-text-up': '#286127',
    '--title-area': '#a08f6b',
    '--title-color': '#ffffff',
    '--title-color2': '#131722',
    '--title-black-color': '#000000',
    '--title-black-color2': '#ffffff',
    '--scrollbar-color': '#a5a5a5',
    '--scrollbar-hover': '#a5a5a5',

    '--fas-icon': '#e7e7e7',
    '--fas-icon-hover': '#ffffff',
    '--prev-next-hover': 'rgba(0, 0, 0, 0.8)',

    '--btn-name-color': '#b98d26',
    '--btn-name-hover': '#801a11',
    '--btn-name-text': '#ffffff',

    '--grid-text': '#ad0000',
    '--grid-text2': '#cd5c5c',

    '--bg-white-color': '#d4d4d4',
    '--bg-white-color2': '#f1f1f1',
    '--bg-black-color': '#0e0e0e',
    '--background-tertiary-shadow': '0 20px 30px rgb(0 0 0 / 10%)',
    '--box-tertiary-shadow': '0 0 50px rgb(0 0 0 / 50%)',

    '--logo-image': 'url(assets/img/footer-logo2.png)',
    '--chart-theme': 'light',
    '--banner-image': 'url(assets/img/banner1white.jpg)',
    '--banner-image2': 'url(assets/img/banner2dark.jpg)',


    '--phone-image': 'url(assets/img/phone1.png)',
    '--applogo-image': 'url(assets/img/applogo2.png)',

    '--content-icon1': 'url(assets/img/1-white.png)',
    '--content-icon2': 'url(assets/img/2-white.png)',
    '--content-icon3': 'url(assets/img/3-white.png)',
    '--content-icon4': 'url(assets/img/6-white.png)',
    '--content-bg': '#121212',
  }
};
