<div class="container">
  <p>Rona Döviz Ve Kıymetli Madenler, tedarik zincirinin herhangi bir noktasında değerli metallerin kara para aklamaya
    iştirak ve terörizmi finanse etme, çocuk işçi çalıştırma dahil insan haklarını ihlal etme ile ilişkilendirilmeme
    sorumluluğunun bilincindedir.</p>

  <p>Rona Döviz Ve Kıymetli Maden Ticareti Yetkili Müessese A.Ş.&nbsp;tüm birimleriyle suç gelirlerinin aklanması-kara
    para aklama, terörün finansmanı ve bağlantılı diğer suçlar ile olan mücadelede gerek ulusal gerekse uluslararası
    alandaki tüm çabaları desteklemeyi, ulusal ve uluslararası tüm yasalara, düzenlemelere ve mevzuatlara uymayı ilke
    edinmiştir.</p>

  <p>İyi niyetli olmayan kişilerle iş yapma riskini en aza indirerek sadece profesyonel olarak iş yapan müşterilerle
    çalışmaktadır. Ayrıca mahalli kanunların hilafına çocuk iş gücü çalıştıran müşterilerle de iş yapmamaktadır.</p>

  <p><em><a href="/content/upload/Müşteri-kabul-politikası.pdf"><span style="color:#2980b9;">Rona Döviz ve Kıymetli Madenler&nbsp; Müşteri Kabul Politikası ve Risk Değerlendirme Prosedürü için tıklayınız.</span></a></em>
  </p>

  <p><em><a href="http://www.lbma.org.uk/assets/market/gdl/RGG%20v6.0%20201508014.pdf" target="_blank"><span
    style="color:#2980b9;">LBMA Responsible Gold Guidance</span></a><br>
    LBMA Sorumlu Altın Rehberi</em></p>

  <p><em><a href="http://www.oecd.org/daf/inv/mne/GoldSupplement.pdf" target="_blank"><span style="color:#2980b9;">OECD Due Diligence Guidance Gold Supplement</span></a><br>
    OECD Tedarik Zinciri Rehberi Altın Eki.</em></p>

  <p><em><a href="http://www.gold.org/sites/default/files/documents/Conflict_Free_Gold_Standard_English.pdf"
            target="_blank"><span style="color:#2980b9;">World Gold Council Conflict-Free Gold Standart</span></a><br>
    Dünya Altın Konseyi Çatışmalardan Etkilenmemiş Altın Standartı</em></p>

  <p><em><a href="http://www.masak.gov.tr/" target="_blank"><span
    style="color:#2980b9;">MASAK yönetmeliği</span></a><br>
    <a href="http://www.borsaistanbul.com/" target="_blank"><span
      style="color:#2980b9;">Borsa İstanbul Yönetmeliği</span></a><br>
    <a href="/Content/Upload/Bağımsız-denetim-raporu-2019.pdf"><span style="color:#2980b9;">Bağımsız&nbsp; Denetim Raporu 2019</span></a><br>
    <a href="/content/upload/MÜSTERİ-TANI-FORMU.pdf" target="_blank"><span style="color:#2980b9;">Müşteri Tanıma Formu&nbsp;</span></a></em>
  </p>

  <p><em><a href="/content/upload/Cerceve_Sozlesme.pdf"><span style="color:#2980b9;">Çerçeve Sözleşme</span></a></em>
  </p>

  <p><em><a href="/content/upload/Musteri_Kabul_Beyani.pdf"><span style="color:#2980b9;">Müşteri Kabul Beyanı</span></a></em>
  </p>

  <p><em><a href="/content/upload/Musteri_Tani_Formu.pdf"><span
    style="color:#2980b9;">Müşteri Tanı Formu</span></a></em></p>

  <p><em><a href="/content/upload/Musteri_Yetki_Belgesi.pdf"><span
    style="color:#2980b9;">Müşteri Yetki Be</span></a><span style="color:#2980b9;">lgesi</span></em></p>

  <p>&nbsp;</p>

  <p>&nbsp;</p>

  <p>&nbsp;</p>


  <br><br><br><br><br><br><br><br><br><br><br>
</div>
