<div class="about-container">
  <div class="info">
    <div class="container">
      <div class="text col-sm-12 col-md-12 col-lg-12">
        <h1 class="title">HAKKIMIZDA</h1>
        <p class="paragraph">
          İçerik girilecek.
        </p>
      </div>
    </div>
  </div>
</div>
