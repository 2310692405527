import {Component, OnInit} from '@angular/core';
import {WarningComponent} from '../warning-component/warning.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openNav() {
    document.getElementById('myNav').style.height = '100%';
    document.getElementById('myNav').style.width = '100%';
  }

  closeNav() {
    document.getElementById('myNav').style.height = '0%';
    document.getElementById('myNav').style.width = '0%';
  }

  public openWarning() {
    const dialogRef = this.dialog.open(WarningComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      // statüsü yeni olanları tekrar çağırıp tabloyu refreshliyorum
    });
  }
}
