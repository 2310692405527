import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    MatDialogModule
  ],
  exports: [
    MatDialogModule
  ],
})

export class AngularMaterialModule {
}
