<div class="home-container col-12">
  <div style="display: flex;flex-wrap: wrap;height: 100vh;overflow-y: auto;" class="col-lg-8 col-md-12 col-sm-12">
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">

      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              DOVIZ
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv('currencyContainer')">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="currencyContainer">
          <div class="grid-box" *ngFor="let data of currencyList ; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(data)"
                 [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{data.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="data.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="data.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{data.Bid.toFixed(3) | number:'1.4-4'}}
              </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{data.Ask.toFixed(3) | number:'1.4-4'}}
              </span>
                <!--ASK END-->
                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              ALTIN
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv('goldContainer')">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="goldContainer">
          <div class="grid-box" *ngFor="let socketData of goldList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid.toFixed(3) | number:'1.2-4'}}
       </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask.toFixed(3) | number:'1.2-4'}}
              </span>
                <!--ASK END-->
                <span *ngIf="socketData.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange==0 || !socketData.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{socketData.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              PARITE
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv('parityContainer')">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="parityContainer">
          <div class="grid-box" *ngFor="let socketData of parityList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid.toFixed(3) | number:'1.4-4'}}
              </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask.toFixed(3) | number:'1.4-4'}}
              </span>
                <!--ASK END-->
                <span *ngIf="socketData.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange==0 || !socketData.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{socketData.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              DİĞER
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv('otherContainer')">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="otherContainer">
          <div class="grid-box" *ngFor="let socketData of otherList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid.toFixed(3) | number:'1.2-4'}}
              </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask.toFixed(3) | number:'1.2-4'}}
              </span>
                <!--ASK END-->
                <span *ngIf="socketData.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{socketData.askPercentChange}}
          </span>
                <span *ngIf="socketData.askPercentChange==0 || !socketData.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{socketData.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="piece-div col-sm-12 col-md-12 col-lg-12">
      <div class="chart">
        <app-chart [code]="code"></app-chart>
      </div>
    </div>
  </div>

  <div style="height: 100vh;overflow-y: auto;" class="col-lg-4 col-md-12 col-sm-12">
    <div class="piece-div col-sm-12 col-md-12 col-lg-12">
      <div class="title-rss">
        HABERLER
      </div>
      <div class="rss-container">
        <app-rss></app-rss>
      </div>
    </div>
  </div>
</div>
