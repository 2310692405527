<div class="kurumsal-container">
  <div class="kurumsal">
    <div class="container">
      <div class="text col-sm-12 col-md-12 col-lg-12">
        <h1 class="title">KURUM POLİTİKAMIZ</h1>
        <p class="paragraph">
          Suç Gelirlerinin Aklanması, Terörizmin Finansmanı ve Kitle İmha Silahlarının Yayılmasının Finansmanı ile Mücadelede KURUM Politikamız… 
          <em><a class="politika" 
            href="../../../assets/img/Politika1.pdf" 
            target="_blank">TIKLAYINIZ... </a>  </em>
        </p>
        <h4 class="title"> MÜŞTERİ TANI</h4>
        <p class="paragraph">
          Tataroğlu Döviz Yetkili Müessese A.Ş. tüm birimleriyle suç gelirlerinin aklanması-kara para aklama, terörün finansmanı ve bağlantılı diğer suçlar ile olan mücadelede gerek ulusal gerekse uluslararası alandaki tüm çabaları desteklemeyi, ulusal ve uluslararası tüm yasalara, düzenlemelere ve mevzuatlara uymayı ilke edinmiştir.
          Tataroğlu Döviz Yetkili Müessese A.Ş. 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun ve ilişkili mevzuat gereğince, Müşterinin Tanınmasına ilişkin Esaslara uygun şekilde Müşteri Kabul Politikası uygular.
        </p>
        <p class="paragraph">
          İş ilişkisi başlatmak için gerekli BİLGİ / FORM / DÖKÜMANLAR için 0(212) 677 66 66 numaralı telefondan irtibata geçiniz.
        </p>
      </div>
    </div>
  </div>
</div>
