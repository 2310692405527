import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './Home/home.component';
import {ROUTING} from './shared/routing';
import {CurrencyComponent} from './currency/currency.component';
import {GoldComponent} from './gold/gold.component';
import {NotFoundComponent} from './_components/not-found/not-found.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {KurumsalComponent} from './kurumsal/kurumsal.component';

const routes: Routes = [
  {
    path: ROUTING.HOME,
    component: HomeComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Anasayfa',
    }
  },
  {
    path: ROUTING.CURRENCY,
    component: CurrencyComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Döviz',
    }
  },
  {
    path: ROUTING.GOLD,
    component: GoldComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Altın',
    }
  },
  {
    path: ROUTING.INFORMATION,
    component: AboutComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Hakkımızda',
    }
  },
  {
    path: ROUTING.KURUMSAL,
    component: KurumsalComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Kurumsal',
    }
  },
  {
    path: ROUTING.CONTACT,
    component: ContactComponent,
    canActivate: [],
    data: {
      breadcrumb: 'İletişim',
    }
  },
  {
    path: ROUTING.ERROR,
    component: NotFoundComponent,
    canActivate: [],
    data: {
      breadcrumb: 'Hata',
    }
  },
  // otherwise redirect to home
  {
    path: '**', redirectTo: ROUTING.HOME, data: {
      breadcrumb: 'Anasayfa',
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
